//Google Tag Manager Event Firing
function GTMAddToCart(SKU, QTY) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'add-to-cart',
    	'productSKU': SKU,
    	'quantity': QTY
    });
}

function GTMRemoveFromCart(SKU, QTY) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'remove-from-cart',
    	'productId': SKU,
    	'quantity': QTY
    });
}

function GTMFireGAEvent(Category, Action, Label) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	"event": "fireGaEvent",
    	"eventCategory": Category,
    	"eventAction": Action,
    	"eventLabel": Label
    });
}

function GTMFirecheckoutStep(GTMProductList, GTMCheckoutStep) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'checkout-step',
    	'ecommerce':
            {
            	'checkout':
					{
						'actionField':
							{
								'step': GTMCheckoutStep,
								'option': ''
							}, 'products': GTMProductList
					}
            }
    });
}

function GTMFireProductImpressionList(GTMProductImpressionList, ListName) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'product-impression',
    	'productList': ListName,
    	'products': GTMProductImpressionList
    });
}

function GTMFireProductDetailView(GTMProductImpression, ViewType, ParentProductID) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'product-details',
    	'viewFormat': ViewType, //Quick View or Full View
    	'parentID': ParentProductID,
    	'products': GTMProductImpression
    });
}

function GTMFireProductClick(ClickElement, ProductSKU) {
	//initialize global JavaScript variable: dataLayer
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(
    {
    	'event': 'product-click',
    	'clickElement': ClickElement,
    	'productSKU': ProductSKU
    });
}


var ACE = ACE || {};
ACE.TagManager = ACE.TagManager || {};

ACE.TagManager.FireUtagLink = function (eventData, callback) {
    if (window.utag !== undefined) {
        utag.link(eventData, callback);
    } else if (callback !== undefined) {
        try {
            callback();
        } catch (e) { console.log(e); }
    }
};

ACE.TagManager.ItemAddedToCart = function (addToCartResult, callback) {
	if (addToCartResult.TealiumData) {
		ACE.TagManager.FireUtagLink(addToCartResult.TealiumData, callback);
	}
	$.each(addToCartResult.AddedItems, function (index, value) {
		GTMAddToCart(value.SKU, value.Quantity); // Track the add
	});
};

ACE.TagManager.ItemQuantityUpdated = function (cartEvent, callback) {
	ACE.TagManager.FireUtagLink(cartEvent, callback);
};

ACE.TagManager.ItemRemovedFromCart = function (cartEvent, sku, quantity, callback) {
    GTMRemoveFromCart(sku, quantity);

    ACE.TagManager.FireUtagLink(cartEvent, callback);
};

var ACE = ACE || {};
ACE.TagManager = ACE.TagManager || {};

$(function () {

    ACE.TagManager.LoadProductAttributeImpressions = function (selector) {
        var startObj = selector;
        if (selector != undefined) {
            if (!selector instanceof jQuery) {
                startObj = $(selector);
            }
        } else {
            startObj = $('body');
        }
        var productGroups = [];
        startObj.find("[data-product_impressiongroup]")
			.addBack("[data-product_impressiongroup]")
			.each(function (i, e) {
			    var jElement = $(this);
			    var groupName = jElement.data('product_impressiongroup');
			    var foundGroup = $.grep(productGroups, function (g, i) { return g.name === groupName; });
			    var productGroup = null;
			    if (foundGroup.length == 1) {
			        productGroup = foundGroup[0];
			    } else {
			        productGroup = { name: groupName, products: [] };
			        productGroups.push(productGroup);
			    }
			    jElement.find("[data-product_sku]")
					.addBack("[data-product_sku]")
					.each(function (ci, ce) {
					    var product = $(this);
					    var impression = {
					        id: product.data('product_id'),
					        sku: product.data('product_sku'),
					        name: product.data('product_name'),
					        category: product.data('product_category'),
					        variant: parseInt(product.data('product_variant')),
					        price: parseFloat(product.data('product_price')),
					        quantity: parseInt(product.data('product_quantity')),
					        position: ci + 1
					    };
					    productGroup.products.push(impression);
					});
			});
        $.each(productGroups, function (i, p) {
            GTMFireProductImpressionList(p.products, p.name);
        });
    };

	var FireTealiumProductClick = function (productCategory, productSku, productName) {
	    utag.link({
	        "event_action": "Product Click",
	        "event_name": "product_click",
	        "event_category": "Ecommerce",
	        "event_label" : [productName],

	        "product_category": [productCategory],
	        "product_id": [productSku],
	        "product_name": [productName]
	    });
	};

    ACE.TagManager.FireTealiumLeadSubmission = function (newsletter,customerEmail) {
	    utag.link({
            "account_type": "Contact",
            "customer_email": customerEmail,
            "event_action": "EmailSignUp",
	        "event_category": "LeadCapture",
	        "event_label": newsletter,
	        "event_name": "lead_submission",

	        "lead_type": newsletter
	    });
	};

	ACE.TagManager.FireTealiumDownloadEvent = function (leadCaptureType,customerEmail,customerPhone) {
	    utag.link({
            "account_type": "Contact",
            "customer_email": customerEmail,
            "customer_phone": customerPhone,
            "event_action": "downloads",
	        "event_category": "form-download",
	        "event_label": leadCaptureType,
	        "event_name": "lead_submission",

	        "lead_type": leadCaptureType
	    });
	};

	ACE.TagManager.FireClientEvent = function (name, category, action, label, additionalData) {
		utag.link($.extend({},
			{
				"event_action": action || name,
				"event_category": category,
				"event_label": label,
				"event_name": name
			}, additionalData));
	};

	ACE.TagManager.ProductClicked = function (e) {
	    var $element = $(this);
	    var sku = $element.data('product_sku');
	    var category = $element.data('product_category');
	    var name = $element.data('product_name');

	    FireTealiumProductClick(category, sku, name);
	    GTMFireProductClick($element.data('product_clicklabel') || 'Product Click', sku);
	};

	ACE.TagManager.LaunchCourseEvent = function (e, tealiumData, courseData) {
	    if (typeof e === 'object' && e.button == 0 || e.button == 1) {
	        utag.link(tealiumData);

	        //call webAPI
	        $.ajax({
	            url: '/api/CourseLaunch',
	            type: 'POST',
	            contentType: "application/json; charset=utf-8",
	            data: JSON.stringify(courseData)
	        });
	    }
	};

	$('body').on('click', '[data-product_sku]', ACE.TagManager.ProductClicked);
	ACE.TagManager.LoadProductAttributeImpressions();
});
(function ($, document, window, viewport) {
  	$.fn.navPanes = function () {
		var openPane = false;
		var navButtons = $(this).find("a");
		var viewPortIsLgUp = viewport.is(">=lg");

		// 'Back' buttons
		$(".nav-pane__back").click(function () {
			$(navButtons).filter(".expanded").removeClass("expanded");
			var parentPane = $(this).parent(".nav-pane");
			hidePane(parentPane);
		});

		// Init nav buttons
		return navButtons.each(function () {
			var config = {
				elem: this,
				target: $(this).attr("href"),
			};

			// Setup handlers
			$(config.elem).click(function (e) {
				e.preventDefault();
				var selfClick = $(this).hasClass("expanded");
				hidePane($(".nav-pane:visible"));

				if (!selfClick) {
					$(".main-head__nav .expanded").removeClass("expanded");
					$("html").addClass("nav-active");
					$(".main-head").addClass("selected");
					$(this).addClass("expanded");

					if (!openPane) {
						revealPane(config.target);
					}
				} else {
					$("html").removeClass("nav-active");
					$(".main-head").removeClass("selected");
					$(this).removeClass("expanded");
				}
			});
		});

		// Hide a pane
		function hidePane(oldPane, newPane, callback) {
			// Separate behavior for small and large screens
			if (viewPortIsLgUp) {
				$(oldPane).hide();
			} else {
				var scrollPos = $(window).scrollTop();
				var navOffset = $(".main-head__nav").offset();
				var paneStyles = {
					display: "block",
					position: "fixed",
					top: navOffset.top - scrollPos,
					left: 0,
				};

				$(oldPane)
					.css(paneStyles)
					.stop()
					.animate({ left: "100%" }, function () {
						$(this).css("display", "none");
					});
			}

			// No more open panes
			openPane = false;

			// If we want to reveal a new pane after closing this one
			if (typeof callback === "function") {
				callback(newPane);
			}
		}

		// Reveal a pane
		function revealPane(newPane) {
			// Separate behavior for small and large screens
			if (viewPortIsLgUp) {
				var navHeight = $(".main-head__nav").outerHeight();
				var paneStyles = {
					display: "none",
					position: "absolute",
					top: navHeight + "px",
					left: 0,
				};

				$(newPane).css(paneStyles).fadeIn("fast");
			} else {
				var scrollPos = $(window).scrollTop();
				var navOffset = $(".main-head__nav").offset();
				var navPos = $(".main-head__nav").position();
				var paneStyles = {
					display: "block",
					position: "fixed",
					top: navOffset.top - scrollPos,
					left: "100%",
				};

				$(newPane)
					.css(paneStyles)
					.stop()
					.animate({ left: 0 }, function () {
						$(this).css({
						position: "absolute",
						top: navPos.top,
						});
					});
			}

			// There is an open pane
			openPane = true;

			//Record Marquee Impressions
			var newPaneSelector = "#" + $(newPane).prop("id") + " [data-mqk]:visible";
			$(newPaneSelector).each(function (index) {
				utag.link({
					event_action: "Impression",
					event_category: "Marquees",
					event_label:
						$(this).closest("div[data-mqs]").attr("data-mqs") +
						" : " +
						$(this).data("mqk"),
					ga_noninteraction: "1",
				});
			});
		}
	};
})(jQuery, document, window, ResponsiveBootstrapToolkit);

$.fn.stickIt = function(params){
	return this.each(function(){
		var config = {
			elem: this,
			elemPos: $(this).offset().top
		};

		if (typeof params !== 'undefined'){
			config.offsetPos = params.offsetBy.outerHeight();
		} else {
			config.offsetPos = 0;
		}

		// Check on initialization
		checkPos(config);

		// Check on page scroll
		$(window).on('scroll', function(){
			checkPos(config);
		});

		// Check on window resize
		$(window).resize(function(){
			checkPos(config);
		});
	});

	function checkPos(config){
		var scrollPos = $(window).scrollTop();

		if(scrollPos > config.elemPos - config.offsetPos){
			$(config.elem).addClass('sticky').css({top: config.offsetPos});
		} else {
			$(config.elem).removeClass('sticky').css({top: 'auto'});
		}
	}
};

/* =====================================================
Adaptive Accordion:
Determines whether to display a bootstrap collapse as an accordion or not
based on viewport size.
uses: /plugins/bootstrap-toolkit.js
example usage on: certification/crt3.1.1-how-to-detail.html
====================================================== */
(function($, document, window, viewport) {

		$.fn.adapativeAccordion = function(options) {

				// Eject if there's an empty call
				if (!$(this).length) {
						return false;
				};

				var panels = this;

				var settings = $.extend({
						currentViewportContext: null,
						currentDisplayState: null
				}, options);

				function checkContext() {
						// if viewport is desktop size and panels are in mobile display state
						if (viewport.is(">=md") && settings.currentDisplayState === 'mobile') {
								// change to desktop view
								setDisplay('desktop');
						}
						// if viewport is mobile size and panels are in desktop display state
						if (viewport.is("<md") && settings.currentDisplayState === 'desktop') {
								// change to mobile view
								setDisplay('mobile');
						}
						// otherwise the viewport change is still within the
						// current display state boundries so there's noting to do
				}

				function setDisplay(type) {
						if (type === 'desktop') {
								// expand the content to display as cubes
								panels.collapse('show');
								// update settings
								settings.currentDisplayState = type
						}
						if (type === 'mobile') {
								// hide the panel nav and show accordion
								panels.collapse('hide');
								// update settings
								settings.currentDisplayState = type;
						}
						// update settings
						settings.currentViewportContext = viewport.current();
				}

				function initializeAdaptiveAccordion() {
						if (viewport.is(">=md")) {
								panels.collapse('show');
								settings.currentDisplayState = "desktop"
						} else {
								panels.collapse('hide');
								settings.currentDisplayState = "mobile";
						}

				};

				$(window).resize(viewport.changed(function() {
						// if the current viewport is different from the current setting
						if (viewport.current() !== settings.currentViewportContext) {
								// var vpWas = settings.currentViewportContext;
								// var vpNow = viewport.current();
								// console.log("viewport changed from " + vpWas + " to " + vpNow)
								// settings.currentViewportContext = vpNow;
								// check the new context to see if the display needs to change
								checkContext();
						}
				}, 150));

				// Intialize it on the element
				initializeAdaptiveAccordion();

		}

})(jQuery, document, window, ResponsiveBootstrapToolkit);

$.fn.vertAlign = function(){
	return this.each(function(){
		var height = $(this).outerHeight();
		var pullUp = height / 2;
		$(this).css({
			top: '50%',
			marginTop: '-'+pullUp+'px'
		});
	});
};

var ACE = ACE || {};

$(document).ready(function() {

	/* =====================================================
    Main Head & Nav
    ====================================================== */

	(function mainHead(){
		var initialWinWidth = $(window).width();

		// Main nav hide/reveal
		var prevScroll;

		$('.main-head__nav-reveal').click(function(e){
			var openNav = $('.main-head').hasClass('selected');

			if (!openNav) {
				// Reveal the nav
				prevScroll = window.scrollY; // Save current scroll position
				$('html').addClass('nav-active');
				$('.main-head').addClass('selected');
				$('.main-head__nav').fadeIn('fast');
			} else {
				// Close the nav
				$('html').removeClass('nav-active');
				window.scrollTo(0, prevScroll); // Return to scroll position
				$('.main-head__nav a.expanded').trigger('click'); // Hide open nav panes
				$('.main-head__nav').fadeOut('fast', function(){
					$('.main-head').removeClass('selected');
				});
			}

			e.preventDefault();
		});

		// Sticky main-head
		$('.main-head').stickIt();

		// Nav panes hide/reveal
		$('.main-head__nav').navPanes();

		// Reset nav on screen size change
		$(window).resize(function(){
			if ($(window).width() - initialWinWidth >= 20 || $(window).width() - initialWinWidth < 0) {
				$('html').removeClass('nav-active');
				$('.main-head__nav a.expanded').trigger('click'); // Hide open nav panes
				$('.main-head').removeClass('selected');
				$('.main-head__nav').hide(0); // CSS on large screens will override this
				initialWinWidth = $(window).width();
			}
		});


		// Main Head Slide Search
		$(document).on('click', function(e) {
			if ($('.main-head__slide-search-reveal').has(e.target).length == 0 && $('.main-head__slide-search').has(e.target).length == 0) {
				$(".main-head__slide-search").removeClass('expanded');
			} else {
				$(".main-head__slide-search").addClass('expanded');
				$(".main-head__slide-search td.gsc-search-button input.gsc-search-button").attr("src", "/images/layout/2017/dist/svgo-magnifying-glass-red.svg");
				setTimeout(function(){
					$(".main-head__slide-search").find("input.gsc-input").focus();
				}, 250);
			}
		});

		$(".main-head__search-row td.gsc-search-button input.gsc-search-button").attr("src", "/images/icon-search-white.png");

		// Signout Button
		$('.header-sign-out-link').click(function (e) {
			e.preventDefault();
			$.ajax({
				url: '/myace/logout',
				type: "POST",
				//dataType: "html",
				success: function (data) {
					window.location.reload();
				}
			});
		});

		// Signin icon
		$('.header-login__icon').click(function (e) {
			e.preventDefault();
			$('.header-login__sub-nav').toggleClass('info-visible');
		});


		// Cart Widget
		updateCartWidget()
	}());


	/* =====================================================
	Tracking Event Listeners
	====================================================== */

	(function trackingListeners(){
		/* =====================================================
		Click => Top Navigation
		====================================================== */
		$('div.nav-pane').on('click', 'a', function () {
			utag.link({
				"event_action": "Click",
				"event_category": "Top Nav Interaction",
				"event_label": $(this).closest("div.nav-pane").attr('id') + " : " + $(this).attr('href')
			});
		});

		/* =====================================================
		Click => Marquee
		====================================================== */
		$('div.MarqueeManager, div.marquee-manager').on('click', '[data-mqk]', function () {
			var marqueeName = $(this).closest("div[data-mqs]").attr('data-mqs') + " : " + $(this).data("mqk");
			utag.link({
				"event_action": "Click",
				"event_category": "Marquees",
				"event_label": marqueeName,
				"ga4_event_name": "select_promotion",
				"promo_name": marqueeName

			});
		});
	}());


    /* =====================================================
	Fancybox handlers
	====================================================== */

    (function fancyboxHandlers(){
        function bindFancyBoxDynamicClick(selector, fancyboxOptions) {
            $(document).on('click', selector, function () {
                var $this = $(this);

                $.fancybox.open($this, fancyboxOptions);

                return false;
            });
        }

        bindFancyBoxDynamicClick('.fancybox', {
            type: 'iframe',
            iframe: {
                preload: false,
                scrolling: true,
                css: {
                    padding: '20px 0px 20px 0px',
                    width: '90%',
                    maxWidth: '1000px'
                }
            },
            touch: {
                vertical: false
            }
        });

        setTimeout(function () {
            if ($.colorbox == undefined) {
                $('.ColorBoxMaster').fancybox({
                    iframe: {
                        preload: false,
                        scrolling: true,
                        css: {
                            padding: '20px 0px 20px 20px',
                            width: '90%',
                            maxWidth: '723px'
                        }
                    },
                    touch: {
                        vertical: false
                    },
                    type: 'iframe'
                });
            }
        }, 50);

        $('.overlay-sub-item-trigger').fancybox({
            beforeLoad: function (instance, current) {
                var $ele = current.opts.$orig;
                var targetContent = '#' + $ele.data('overlay-sub-item-target');
                var overlayTitle = $(targetContent).data('overlay-sub-item-title');

                $('.standard-overlay__sub-item').hide();
                $(targetContent).closest('.standard-overlay').find('.standard-overlay__title').html(overlayTitle);
                $(targetContent).show();
            },
            infobar: false,
            buttons: false,
            smallBtn: false,
            touch: false
        });

		$('.overlay-reveal').fancybox({
			infobar: false,
			buttons: false,
			smallBtn: false,
			touch: false
		}).each(function () { // auto launch if the hash is in the url
			var fb = $(this);
			if (this.hash && this.hash.length > 0 && this.hash == window.location.hash) { fb.click() }
		});

        $('.overlay-reveal-video').fancybox({
	        width: '650',
	        autoSize: true,
	        type: 'iframe'
	    });

		$('.overlay-reveal-tc').fancybox({
			width: '650',
			infobar: false,
			buttons: true,
			smallBtn: false,
			touch: true,
			iframe: {
				scrolling: 'yes'
            },
            type: 'iframe'
		});

		window.LaunchFancyboxFrame = function (launchUrl, preload, preventModal) {
			var fancyBoxOpts = {
				modal: !preventModal,
				infobar: false,
				touch: {
					vertical: false
				},
				smallBtn: true,
				toolbar: false,
				iframe: {
					preload: preload,
					css: {
						padding: '5px',
						width: '768px',
						overflow: 'auto'
					}
				}
			};
			if (preload && preventModal) {
				fancyBoxOpts.baseClass = "fancybox-close-btn-inside";
            }
			$.fancybox.open({
				src: launchUrl,
				type: 'iframe',
				opts: fancyBoxOpts
			});
			return false;
		};
	}());


    /* =====================================================
	ACE Form handlers
	====================================================== */

	(function aceFormHandlers(){
		// Form label animation

		$('.input-reveal-wrap input').focus(function(){
			$(this).closest('.input-reveal-wrap').addClass('selected');
		}).blur(function(){
			if ( !$(this).val() ) {
				$(this).closest('.input-reveal-wrap').removeClass('selected');
			}
		});

		// Filename as label for input[type=file]

		$('.ace-form__group .btn-file input[type="file"]').each(function(){
			$(this).change(function(){
				var parentGroup = $(this).closest('.ace-form__group');
				var fileNameLabel = parentGroup.find('.btn-file-name');
				parentGroup.addClass('selected');
				fileNameLabel.html(this.files[0].name);
			});
		});

		// ACE Form Group Tips

		$(document).click(function (event) {
			var clickedEl = $(event.target);
			var allTips = $('.ace-form__group-tip-text');
			var thisTip = clickedEl.closest('.ace-form__group-tip').find('.ace-form__group-tip-text');

			// SHOW ON ICON CLICK
			if(clickedEl.hasClass("ace-form__group-tip-icon") || clickedEl.parents(".ace-form__group-tip-icon").length){
				if (thisTip.is(":visible")) {
					thisTip.hide();
					return;
				}
				allTips.not(thisTip).hide();
				thisTip.show();
			}
			// HIDE ON OFF-CLICK
			else if(!clickedEl.hasClass("ace-form__group-tip-text") && !clickedEl.parents(".ace-form__group-tip-text").length) {
				allTips.hide();
			}
		});
	}());


	/* =====================================================
	YouTube Overlay from Query String
	====================================================== */

	(function ytPlayerFromQueryString(){
		let playVidParam = getURLParameter("playVid");
		if (playVidParam) {
			playVidParam = playVidParam.toLowerCase();

			switch (playVidParam) {
				case "75k":
					launchYouTubeOverlay("duP63mBuDVg");
					break;

				case "ift":
					launchYouTubeOverlay("Ydzk4xeo2FY");
					break;

				case "brandanthem":
					launchYouTubeOverlay("pG4BEGEQHiA");
                    break;

                case "fasttrack":
					launchYouTubeOverlay("O37FKTbx-Nw");
					break;
				case "ptv6-fcp":
					launchYouTubeOverlay("s-LwSYZuMd8");
					break;
				case "mover":
					launchYouTubeOverlay("abYm0Sx4W_k");
					break;
				case "gfisneakpeek":
					launchYouTubeOverlay("Fr3iQWXdw9g");
					break;
				default:
					break;
			}
		}
	}());


	/* =====================================================
	Click Element with matching ID from Query String
	====================================================== */

	(function clickElByIdFromQueryString(){
		if (typeof getWhiteListedIDs === "function") {
			const whitelist = getWhiteListedIDs();
			const el2clk = {
				id: getURLParameter("el2clk"),
				whitelist // whitelisted ids are in marquee (Sitewide-Data-Container)
			};

			if (el2clk.id && el2clk.whitelist.length > 0 && el2clk.whitelist.indexOf(el2clk.id) >= 0) {
				document.getElementById(el2clk.id).click();
			}
		}
	}());


	/* =====================================================
	Smooth Scrolling: for anchor links
	====================================================== */

	$('a[href*="#"].jsSmoothScroll').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			smoothScrollToElement(target);
		}
	});

	/* =====================================================
	Accordions
	used on: global main footer link lists
	====================================================== */
	$('.accordion-wrap').on('click', '.accordion-trigger', function(e){
	    var selfClick = $(this).hasClass('selected');
	    var openContent = $(this).parents('.accordion-wrap').find('.accordion-trigger.selected');

	    if(!selfClick) {
	        openContent.toggleClass('selected').siblings('.accordion-content').slideToggle(350);
	    }
	    $(this).toggleClass('selected');
	    $(this).siblings('.accordion-content').slideToggle(350);
	});


	/* =====================================================
	Sticky - Page Tools
	used on: 15 separate views
	====================================================== */

	$('.page-tools').stickIt({
		offsetBy: $('.main-head')
	});


	/* =====================================================
	Match Height - Hero Tabs
	used on: ACEOnly Bar, Home Page (Cert Toggler)
	====================================================== */

	$('.hero-tabs__tab-nav .hero-tabs__tab').matchHeight();

	/* =====================================================
	Lazyloading of Background Images
	used on: Global
	====================================================== */
	lazyBg();

	/* =====================================================
	Record Marquee Impressions
	used on: Global
	====================================================== */
	logMarquee();


	$('.widget--drawer .widget__title').on('click', function(){
		$(this).closest(".widget--drawer").toggleClass("collapsed");
	});

	/* =====================================================
	CEC FINDER LAUNCH
	====================================================== */
	(function(){
		const overlayStyleBlock = document.createElement("style");

		overlayStyleBlock.innerHTML = `
			.cec-finder-overlay .fancybox-slide { padding: 0; }
			.cec-finder-overlay .fancybox-toolbar { display: none; }`;

			document.head.appendChild(overlayStyleBlock);
	
		// Overlay Options
		const cecFinderFancyboxOpts = {
			type: 'iframe',
			src: '/continuing-education/cec-finder/',
			iframe: {
				preload: true,
				scrolling: true,
				css: {
					padding: 0,
					width: '100%',
					height: '100%',
					maxWidth: 'none'
				}
			},
			baseClass: "cec-finder-overlay",
			smallBtn: false,
			toolbar: false,
			touch: {
				vertical: false
			}
		};
	
		// Launch on Button Click
		$('.btn-launch-cec-finder').fancybox(cecFinderFancyboxOpts);
	
		// Launch when Query String Present
		if (parseInt(getURLParameter("cec-finder")) === 1) {
			$.fancybox.open(cecFinderFancyboxOpts);
		}
	})();

}); // document ready block

function updateCartWidget() {
	$.ajax({
		url: '/api/cart',
		type: "GET",
		success: function (data) {
			$('.header-shopping-cart').attr('href', data.ShoppingCartURL);
			$('.header-shopping-cart__count').html(data.Items.length);

			ACE.Cart = data;

			$(document).trigger("ACE.Cart.Loaded");
		}
	});
}

function getPseudoGuid() {
	function S4() {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	}
	
	// This is NOT an actual guid, but it will work for what we need it to do.
	
	function guid() {
		return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
	}

	return guid();
}

function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

function smoothScrollToElement(targetEl) {
    var target = targetEl;
    if (target.length) {
        var stickyElsOffset = 0;
        var navBarHeight = $('.main-head').hasClass("sticky") ? $('.main-head').outerHeight() : $('.main-head').outerHeight() * 2;
        $('.sticky').not('.main-head').each(function () {
            stickyElsOffset += $(this).outerHeight();
        });
        var scrollLocation = target.offset().top - (navBarHeight + stickyElsOffset);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
            $('html, body').animate({
                scrollTop: scrollLocation
            }, 1000);
            return false;
        }
    }
}

function launchYouTubeOverlay(videoHash) {

	let src = "https://www.youtube.com/embed/" + videoHash + "?rel=0&enablejsapi=1";
	let overlayTemplate = `
				<style>
					#yt-param-video-overlay .fancybox-close-small {
						position: fixed;
						top: 20px;
						right: 20px;
					}
					#yt-param-video-overlay .fancybox-close-small::after {
						border: 0;
						outline: none;
					}
				</style>
				<div id="yt-param-video-overlay" class="spc-p-0" style="background-color: transparent; width: 80%;">
					<div class="spc-m-x-auto" style="width: 100%; max-width: 1200px;">
						<div class="video-responsive">
							<iframe src="${src}"></iframe>
						</div>
					</div>
				</div>
				`;

	const templateHTML = document.createElement("div");
	templateHTML.style.display = "none";
	templateHTML.innerHTML = overlayTemplate;

	document.body.appendChild(templateHTML);

	$.fancybox.open({
		src: "#yt-param-video-overlay",
		type: "inline"
	});
}

function lazyBg(selectorQuery) {
	const lazyBackgrounds = [].slice.call(selectorQuery ? selectorQuery : document.querySelectorAll(".lazybg"));

	// MODERN BROWSERS
	if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
		let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {
					const target = entry.target;

					target.classList.add("lazybg-visible");

					lazyBackgroundObserver.unobserve(target);

					if (target.classList.contains("lazybg-inline")) lazyBgInline(target);

					target.classList.remove("lazybg");
				}
			});
		});

		lazyBackgrounds.forEach(lazyBackground => lazyBackgroundObserver.observe(lazyBackground));
	}
	// IE11 Fallback
	else {
		lazyBackgrounds.forEach(lazyBackground => {
			lazyBackground.classList.add("lazybg-visible");
			if (lazyBackground.classList.contains("lazybg-inline")) lazyBgInline(lazyBackground);
		});
	}

	function lazyBgInline(entry) {
		if (!entry) return;

		const desktopImage = entry.getAttribute("data-lazybg");
		const mobileImage = entry.getAttribute("data-lazybg-m");
		const mobileBreakpoint = entry.getAttribute("data-lazybg-breakpoint");
		const displayImg = () => {
			if (entry) {
				entry.style.backgroundImage = mobileImage && mobileBreakpoint && window.matchMedia(mobileBreakpoint).matches
					? `url(${mobileImage})`
					: `url(${desktopImage})`;
			}
		};

		displayImg();

		window.addEventListener("resize", displayImg);
	}
}

function logMarquee(selectorQuery) {
	const marquees = [].slice.call(selectorQuery ? selectorQuery : document.querySelectorAll('[data-mqk]'));

	if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
		let marqueeObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {

					const target = entry.target;
					const marqueeContainerName = target.closest("div[data-mqs]").getAttribute('data-mqs');
					const marqueeName = marqueeContainerName + " : " + target.getAttribute('data-mqk');

					//Record Marquee Impression Event GA Analytics
					utag.link({
						"event_action": "Impression",
						"event_category": "Marquees",
						"event_label": marqueeName,
						"ga_noninteraction": "1",
						"ga4_event_name": "view_promotion",
						"promo_name": marqueeName
					});

					marqueeObserver.unobserve(target);

				}
			});
		}, { threshold: .75 });
		marquees.forEach(marquee => marqueeObserver.observe(marquee));
	}
}

function BindDropdownWithOtherTextBox(ddlSelector, otherDisplayValue, textInputSelector, textInputWrapper) {
	let $ddlInput = $(ddlSelector)
	let $devOtherWrapper = $(textInputWrapper);
	let $tbOther = $(textInputSelector);
	let onChange = function () {
		if ($ddlInput.val() == otherDisplayValue) {
			$devOtherWrapper.show();
		}
		else {
			$devOtherWrapper.hide();
			$tbOther.val("");
		}
	}
	$ddlInput.change(onChange);
	onChange();
}
